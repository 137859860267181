<template>
  <div class="vld-parent">
    <loading :active="isLoading" 
    :can-cancel="true" 
    :on-cancel="onCancel"
    :is-full-page="fullPage"></loading>
  </div>
  <i class="settings-close mdi mdi-close"></i>
  <ul class="nav nav-tabs" id="setting-panel" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="todo-tab" data-toggle="tab" href="#todo-section" role="tab" aria-controls="todo-section" aria-expanded="true">Suggested Match styles</a>
    </li>
  </ul>
  <div class="tab-content" id="setting-content">
    <div class="tab-pane fade show active scroll-wrapper" id="todo-section" role="tabpanel" aria-labelledby="todo-section">
      <div class="list-wrapper px-3">

          <div class="accordion" id="accordionExample">
            <div class="card" v-for="(style,index) in this.styles">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapseOne'+index" aria-expanded="true" :aria-controls="'collapseOne'+index">
                    {{style.styleorderno}}
                  </button>
                </h2>
              </div>

              <div :id="'collapseOne'+index" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                 <ul style="max-height:250px;overflow-y: scroll;">
                  <li><strong>Dev Number :</strong>DEV-{{style.dn_number}} </li>
                  <li><strong>Party Name :</strong> <span v-if="style.development.party">{{style.development.party.partyname}}</span><span v-else>Not Given</span></li>
                  <li><strong>Party Concern :</strong> <span v-if="style.development.party_concern">{{style.development.party_concern.partyconcernname}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Buyer :</strong> <span v-if="style.development.buyer">{{style.development.buyer.buyername}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Style :</strong> <span v-if="style.styleorderno">{{style.styleorderno}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Order Number :</strong> <span v-if="style.orderno">{{style.orderno}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Fabric Composition :</strong> <span v-if="style.fabric">{{style.fabric.subject_title}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Base Color :</strong><span v-if="style.basecolor_id">{{style.basecolor_id}}</span> <span v-else>Not Given</span> </li>
                  <li><strong>Print Type :</strong> <span v-if="style.printtype">{{style.printtype.print_title}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Artwork Image :</strong> <span v-if="style.artwork"><span v-for="image in style.artwork" data-toggle="tooltip" data-placement="right" :title="`Combo-${image.imagename}`"><img class="img-thumbnail" style="border-radius: 0%;cursor: pointer;margin-right: 5px;display: block;width: 100%;max-height: 100px;min-height: 100px!important;margin-left:10px" :src="this.addminurl+'images/artwork/'+image.atworkimage"  data-toggle="modal" data-target="#exampleModal-10" data-whatever="@mdo" @click="viewimage(this.addminurl+'images/artwork/'+image.atworkimage)" /></span></span> <span v-else>Not Given</span> </li>
                  <li><strong>Artwork Date :</strong> <span v-if="style.artworkdate">{{style.artworkdate}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Designer Name  :</strong> <span v-if="style.designstage">{{style.designstage.user.name}} {{style.designstage.user.lastname}}</span> <span v-else>Not Given</span></li>


                  <li><strong>Screen Type :</strong> <span v-if="style.screentype">{{style.screentype.screen_title}}</span> <span v-else>Not Given</span></li>
                  <li><strong>Design No :</strong> DN-{{style.id}}</li>
                </ul>
                 
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    <!-- To do section tab ends -->
    <div class="tab-pane fade" id="chats-section" role="tabpanel" aria-labelledby="chats-section">
      <div class="d-flex align-items-center justify-content-between border-bottom">
        <p class="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">Users</p>
        <small class="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 font-weight-normal">See All</small>
      </div>
      <ul class="chat-list" v-for="studentlists in this.studentlist">
        <li class="list active">
          <div class="profile"><img :src="addminurl+'profileimg/'+studentlists.img"><span class="online"></span></div>
          <div class="info">
            <p>{{studentlists.name}} {{studentlists.lastname}}</p>
            <p>{{studentlists.phone}}</p>
          </div>
          <small class="text-muted my-auto"> {{moment(studentlists.created_at).fromNow()}}</small>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal fade" id="exampleModal-10" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel-4" aria-hidden="true">
                    <div class="modal-dialog modal-md" role="document">
                      <div class="modal-content" style="background-color:#9aa1a7!important;">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel-4">View Image</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <img class="img-fluid" :src="this.imageurl">
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
              </div>  
</template>
<script type="text/javascript">
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import  Loading  from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import moment from 'moment';


export default{
  components: {
    Loading
  },

  computed:{
    addminurl(){
      return this.$store.state.addminurl;

    },
    url(){
      return this.$store.state.url;
    }
    ,
    token(){
      return this.$store.state.authToken;
    },
    user(){
      return this.$store.state.user;
    },
    styles(){
        return this.$store.state.matchstyles;
    },
  },
  data(){
    return{
      isLoading:false,
      fullPage:true,
      studentlist:[], 
      todolistviews:[], 
      moment,
      todolist:'',
      imageurl:''


    }
  },
  created(){
   console.log(this.styles);
  },

  methods:{
   studentlistview(){
    axios.get(this.addminurl+'api/auth/getstudentslist',{
      headers: {
        Authorization:`Bearer ${this.token}`
      }
    })
    .then(response => {
      this.studentlist=response.data;
      console.log(this.studentlist);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  },
  viewimage(imageurl){
      this.imageurl=imageurl;
  }, 
  todolistsss(){
    this.isLoading=true;
    axios.post(this.addminurl+'api/auth/ToDoListss',{
      classid:this.user.id,
      todolists:this.todolist,

    },{
      headers: {
        Authorization: `Bearer ${this.token}`
      }}).then(response=>{
        console.log(response.data);
        this.isLoading=false;
        this.todolist="";
        toast("subject create suceessfully", {
         autoClose: 1000,
       });
      });

    },
    todolistview(){
      axios.get(this.addminurl+'api/gettodolis')
      .then(response => {
        this.todolistviews=response.data;
       this.todolistview();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

  },
}
</script>
