<template>
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
          <ul class="nav">
            <li class="nav-item nav-profile">
              <div class="nav-link">
                <div class="profile-image">
                  <img :src="addminurl+'profileimg/'+this.user.img"  alt="image"/>
                  <span class="online-status online"></span> <!--change class online to offline or busy as needed-->
                </div>
                <div class="profile-name">
                  <p class="name">
                   {{this.user.name}} {{this.user.lastname}}
                  </p>
                  <p class="designation" v-if="this.user.usertype==1">
                    Admin
                  </p>
                  <p class="designation" v-else>
                    Designer
                  </p>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/">
                <i class="icon-rocket menu-icon"></i>
                   <span class="menu-title">Dashboard</span>
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-layouts" aria-expanded="false" aria-controls="page-layouts">
                <i class="fa fa-users menu-icon" ></i>
                <span class="menu-title">Profile</span>
              </a>
              <div class="collapse" id="page-layouts">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/profile">Admin Profile</router-link></li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" href="#user-layouts" aria-expanded="false" aria-controls="user-layouts">
                          <span class="menu-title">User Profile</span>
                        </a>
                        <div class="collapse" id="user-layouts">
                          <ul class="nav flex-column sub-menu mysubnav">
                            <li class="nav-item"><router-link class="nav-link" to="/user-registration">User Regitration</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/users">Users</router-link></li>
                          </ul>
                        </div>

                    </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-layouts1" aria-expanded="false" aria-controls="page-layouts1">
                <i class="fa fa-cogs menu-icon" ></i>
                <span class="menu-title">Registration Panel</span>
              </a>
              <div class="collapse" id="page-layouts1">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/buyers">Buyer</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/party">Party</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/partyconcern">Party Concern</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/marcandiser">Marketing</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/viewall-department">Department</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/fabric">Fabric</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/viewbasecolor">Base Color </router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/printtypes">Print Type </router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/viewscreen"> Screen Type </router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/viewmachine"> Machine Type </router-link></li>
                </ul>
              </div>
            </li>

            <li class="nav-item d-none d-lg-block">
              <a class="nav-link" data-toggle="collapse" href="#sidebar-layouts" aria-expanded="false" aria-controls="sidebar-layouts">
                <i class="fa fa-picture-o  menu-icon"></i>
                <span class="menu-title">Development Stage</span>
              </a>
              <div class="collapse" id="sidebar-layouts">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/develpmentnumber">Development Number</router-link></li>
                    <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/incompleterndstage">RND Stage</router-link></li>
              <li class="nav-item d-none d-lg-block">
              <a class="nav-link" data-toggle="collapse" href="#page-layouts2" aria-expanded="false" aria-controls="page-layouts">
                <span class="menu-title">Design Stage</span>
              </a>
              <div class="collapse" id="page-layouts2">
                <ul class="nav flex-column sub-menu" style="margin-left:-50px!important;">
                  <li class="nav-item"><router-link class="nav-link" to="/incompletedesign">Pending Design</router-link></li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" href="#user-layouts2" aria-expanded="false" aria-controls="user-layouts">
                          <span class="menu-title">Report</span>
                        </a>
                        <div class="collapse" id="user-layouts2">
                          <ul class="nav flex-column sub-menu mysubnav">
                            <li class="nav-item"><router-link class="nav-link" to="/pendingdesignreport">Pending Design</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/completedesignreport">Complete Design</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/recivedesignreport">Received Design</router-link></li>
                          </ul>
                        </div>

                    </li>
                </ul>
              </div>
            </li>
                    <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/fabricreciveddate">Fabric Receive</router-link></li>
                    <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/incompleteengraving">Engraving Stage</router-link></li>
                    <!-- <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/incompletesample">Sample Satage</router-link></li> -->

             <li class="nav-item d-none d-lg-block">
              <a class="nav-link" data-toggle="collapse" href="#page-layouts10" aria-expanded="false" aria-controls="page-layouts">
                <span class="menu-title">Sample Stage</span>
              </a>
              <div class="collapse" id="page-layouts10">
                <ul class="nav flex-column sub-menu" style="margin-left:-50px!important;">
                  <li class="nav-item"><router-link class="nav-link" to="/handsample">Hand Sample</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/flatbedmachine">Flatbed Machine Sample</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/rotarymachine">Rotary Machine Sample</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/samplereport">Sample Report</router-link></li>
                </ul>
              </div>
            </li>
                <li class="nav-item d-none d-lg-block"><router-link class="nav-link" to="/planing">Planing Satage</router-link></li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/developmentstatus">
                <i class="icon-docs menu-icon"></i>
                   <span class="menu-title">Development Status</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/completedevelopment">
                <i class="icon-docs menu-icon"></i>
                   <span class="menu-title">Complete Development</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/backupfile">
                <i class="icon-docs menu-icon"></i>
                   <span class="menu-title">Backup File</span>
              </router-link>
            </li>
            
            <li class="nav-item nav-doc" style="padding-bottom: 50px;">
              <a @click="logout()" class="nav-link bg-primary" href="#">
                <i class="icon-power menu-icon"></i>
                <span class="menu-title">Logout</span>
              </a>
            </li>
          </ul>
       </nav>	
</template>
<script>
    export default{
  	name:'navView',
    computed:{

      addminurl(){
        return this.$store.state.addminurl;

      },
      url(){
        return this.$store.state.url;

      },
      user(){
        return this.$store.state.user;
      }


    },
    data(){
      return{
      }
    },
    mounted(){
     console.log(this.user);
    },
    methods:{
      logout(){
        this.$store.dispatch("logout"); 
      },
    },

   }

</script>
